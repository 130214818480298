import React from "react";
import "../../../assets/css/ApplicationERP.css"; 
import CallToAction from "../../CallToActions";
import ERPImg from "../../../assets/images/erp.png"
import CRMImg from "../../../assets/images/crm.jpg"
import ERPImg2 from "../../../assets/images/erp-image.png"


const ERPSection = () => {
  return (
    <div className="erp-container" id="erp">
      {/* Title Section */}
      <div className="erp-header">
        <button className="category-btn" data-aos="fade-up">Applications ERP/CRM</button>
      </div>

      {/* Main Content */}
      <div className="erp-content">
        <div className="erp-intro" data-aos="fade-up">
          <p>
            Dans un environnement en profonde mutation, la compétitivité des
            entreprises dépend de leur clairvoyance et de leur agilité. Les
            <span className="highlight text-dark"> entreprises gagnantes</span> seront
            celles qui se montreront capables d’identifier les changements de
            dynamique, et d’y répondre rapidement et efficacement.
          </p>
          <p>
            C’est précisément tout l’intérêt de la gestion de la relation client
            (<span className="highlight text-dark">CRM</span>) et de la planification des
            ressources d’entreprise (<span className="highlight text-dark">ERP</span>).
          </p>
          <p>
            Un ERP est la solution idéale pour gagner en productivité et
            améliorer les processus, en liant l’ensemble des{" "}
            <span className="highlight text-dark">
              systèmes d’information d’une entreprise.
            </span>{" "}
            Toutefois, la mise en place d’un ERP nécessite d’étudier
            préalablement les besoins spécifiques de chaque département de
            l’entreprise.
          </p>

          <div className="img-div">
            <img src={ERPImg2} alt="Safari Technologies ERP"></img>
          </div>

        </div>

        {/* ERP Section */}
        <div className="erp-section" data-aos="fade-up">
          <button className="erp-card">ERP</button>
          <p>
            Un ERP performant représente un investissement élevé, dont
            l’optimisation dépend directement de la maîtrise des collaborateurs.
            Un <span className="highlight text-dark">AMOA professionnel</span> saura vous
            guider dans cette transformation au succès de votre entreprise.
          </p>
          <p>
            SAFARI a créé une équipe de consultants formés sur les technologies :
          </p>
          <div className="technologies">
            <button className="tech-btn">SAS</button>
            <button className="tech-btn">SAGE</button>
            <button className="tech-btn">SAP</button>
          </div>

          <p>
            Nos consultants sont capables de vous accompagner dans
            l’intégration de vos solutions ERP.
          </p>
        </div>
        <div>
          <img src={ERPImg} alt="Safari Technologies ERP" className="img-erp"></img>
          <img src={CRMImg} alt="Safari Technologies CRM" className="img-erp"></img>

        </div>

        {/* Applications Section */}
        <div className="applications-section" data-aos="fade-up">
          <button className="applications-card">Applications</button>
          <p>
            SAFARI a recruté et formé une équipe de{" "}
            <span className="highlight text-dark">concepteurs développeurs</span> sur les
            technologies <span className="highlight text-dark">JAVA J2E</span> qui sont
            capables de vous accompagner dans vos projets de développement
            d’applications spécifiques à vos besoins. Nos consultants maitrisent
            une grande partie des <span className="highlight text-dark">frameworks</span>{" "}
            et <span className="highlight text-dark">technologies</span> du marché.
          </p>
        </div>
      </div>
      
        < CallToAction text="Vous cherchez une solution ERP/CRM pour votre entreprise?"/>
      
    </div>
  );
};

export default ERPSection;
