import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PolitiqueConfidentialite = () => {
  return (
    <>

    < Header/>
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }} className="container bg-light p-5">
      <h1>Politique de Confidentialité de Safari technologies</h1>
      <section>
        <h2>Introduction</h2>
        <p>
          Chez Safari technologies, nous prenons très au sérieux la protection de
          vos données personnelles. Cette politique de confidentialité explique
          quelles informations nous recueillons, comment nous les utilisons et
          les mesures que nous prenons pour assurer leur sécurité. En utilisant
          notre site web, vous acceptez les pratiques décrites dans cette
          politique.
        </p>
      </section>
      <section>
        <h2>Données Collectées</h2>
        <p>
          Nous pouvons collecter les informations suivantes lorsque vous
          utilisez notre site :
        </p>
        <ul>
          <li>
            <strong>Informations personnelles</strong> : Nom, prénom, adresse
            électronique, numéro de téléphone, etc., lorsque vous remplissez un
            formulaire de contact ou créez un compte.
          </li>
          <li>
            <strong>Informations techniques</strong> : Adresse IP, type de
            navigateur, système d'exploitation, et informations relatives à
            votre navigation sur notre site (pages consultées, durée de visite,
            etc.).
          </li>
          <li>
            <strong>Cookies et technologies similaires</strong> : Pour améliorer
            votre expérience utilisateur, analyser le trafic et personnaliser le
            contenu.
          </li>
        </ul>
      </section>
      <section>
        <h2>Utilisation des Données</h2>
        <p>Les données collectées peuvent être utilisées pour :</p>
        <ol>
          <li>
            Fournir les services demandés (par exemple, répondre à vos demandes
            ou traiter vos commandes).
          </li>
          <li>
            Améliorer notre site web et personnaliser votre expérience
            utilisateur.
          </li>
          <li>
            Établir des communications promotionnelles, sous réserve de votre
            consentement.
          </li>
          <li>Respecter nos obligations légales et réglementaires.</li>
        </ol>
      </section>
      <section>
        <h2>Partage des Données</h2>
        <p>
          Nous ne vendons ni ne louons vos données personnelles à des tiers.
          Cependant, nous pouvons partager vos données avec :
        </p>
        <ul>
          <li>
            Des prestataires de services tiers agissant en notre nom (ex. :
            hébergement, analyse de données).
          </li>
          <li>Les autorités compétentes si requis par la loi.</li>
        </ul>
      </section>
      <section>
        <h2>Sécurité des Données</h2>
        <p>
          Nous mettons en place des mesures de sécurité techniques et
          organisationnelles pour protéger vos données contre tout accès non
          autorisé, perte ou divulgation. Cependant, aucun système n’est
          complètement sécurisé, et nous ne pouvons garantir la sécurité
          absolue de vos informations.
        </p>
      </section>
      <section>
        <h2>Vos Droits</h2>
        <p>
          Conformément à la réglementation applicable, vous disposez des droits
          suivants concernant vos données personnelles :
        </p>
        <ul>
          <li>Accès, rectification et suppression de vos données.</li>
          <li>Opposition au traitement ou limitation de l’utilisation de vos données.</li>
          <li>Portabilité des données.</li>
          <li>Retrait de votre consentement à tout moment.</li>
        </ul>
        <p>
          Pour exercer ces droits, veuillez nous contacter à l’adresse e-mail
          suivante :{" "}
          <a href="mailto:contact@safari-technologies.com">
            contact@safari-technologies.com
          </a>
          .
        </p>
      </section>
      <section>
        <h2>Cookies</h2>
        <p>
          Notre site utilise des cookies pour améliorer votre expérience. Vous
          pouvez configurer votre navigateur pour bloquer les cookies ou
          recevoir une alerte lorsque des cookies sont envoyés. Cependant,
          certaines parties du site pourraient ne pas fonctionner correctement
          sans cookies.
        </p>
      </section>
      <section>
        <h2>Modifications de la Politique de Confidentialité</h2>
        <p>
          Nous nous réservons le droit de modifier cette politique de
          confidentialité à tout moment. Toute modification sera publiée sur
          cette page avec une date de mise à jour. Nous vous encourageons à
          consulter cette page régulièrement.
        </p>
      </section>
      <section>
        <h2>Contact</h2>
        <p>
          Pour toute question concernant cette politique de confidentialité ou
          vos données personnelles, vous pouvez nous contacter :
        </p>
        <address>
          Safari technologies<br />
          Adresse :  1-3 Place de la Berline 93200, Saint Denis<br />
          E-mail :{" "}
          <a href="mailto:contact@safari-technologies.com">
            contact@safari-technologies.com
          </a>
          <br />
        </address>
      </section>
      <footer>
        <p>Date de dernière mise à jour : 30 décembre 2024</p>
      </footer>
    </div>
    <Footer />
    </>
  );
};

export default PolitiqueConfidentialite;
