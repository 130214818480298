import React from 'react';
import '../../assets/css/StructuresPages.css';
import { FaUserTie, FaBriefcase, FaHandshake } from 'react-icons/fa';
import CallToAction from '../CallToActions';
import workflow from "../../assets/images/workflow.jpg"

const SafariParticipations = () => {
  return (
    <div className="participations-container">
      <section className="hero-section-participations">
        <div className="hero-content">
          <h1 className="hero-title">Safari Participations</h1>
          <p className="hero-subtitle">
            Connecter les meilleurs consultants avec nos clients pour des collaborations fructueuses.
          </p>
        </div>
        <div className="hero-overlay"></div>
      </section>

      <section className="about-section">
        <h2 className="section-title">Qui Sommes-Nous ?</h2>
        <p className="about-text">
          <strong>Safari Participations</strong> est au cœur de la stratégie du groupe. Nous identifions, recrutons et plaçons les meilleurs prestataires pour répondre aux besoins spécifiques de nos clients dans divers secteurs.
        </p>
        <p className='about-text'>Notre <strong>objectif</strong> ultime est d’être bien plus qu’un intermédiaire. Nous voulons être un <strong>partenaire de confiance</strong>, engagé dans la réussite à long terme des entreprises que nous accompagnons et des talents que nous recrutons. Chez Safari Participations, chaque mission est une opportunité de bâtir des partenariats solides et d’assurer une collaboration fructueuse.</p>
      </section>

      <section className="process-timeline-section">
        <h2 className="section-title">Notre Processus</h2>
        <div className="timeline">
          <div className="timeline-step">
            <div className="icon-circle">
              <FaUserTie />
            </div>
            <div className="step-content">
              <h3 className="step-title">Recrutement</h3>
              <p>
                Identifier et sélectionner les meilleurs consultants pour répondre aux exigences de nos clients.
              </p>
            </div>
          </div>
          <div className="timeline-step">
            <div className="icon-circle">
              <FaBriefcase />
            </div>
            <div className="step-content">
              <h3 className="step-title">Placement</h3>
              <p>
                Connecter les talents aux projets les plus adaptés et stratégiques.
              </p>
            </div>
          </div>
          <div className="timeline-step">
            <div className="icon-circle">
              <FaHandshake />
            </div>
            <div className="step-content">
              <h3 className="step-title">Suivi</h3>
              <p>
                Assurer un accompagnement constant pour garantir le succès des missions.
              </p>
            </div>
          </div>
        </div>

        
      </section>

      <img className="img-div" src={workflow} alt="Safari Participations Process"></img>
    

      <section className="testimonials-section mt-5">
        <h2 className="section-title">Ce que disent nos clients</h2>
        <div className="testimonials">
          <blockquote>
            <p>
              "Grâce à Safari Participations, nous avons trouvé des consultants hautement qualifiés qui ont fait toute la
              différence dans nos projets stratégiques."
            </p>
            <footer>— Client satisfait</footer>
          </blockquote>
          <blockquote>
            <p>
              "Le processus de recrutement de Safari est fluide et efficace. Leur suivi est impeccable, du début à la fin."
            </p>
            <footer>— Responsable RH</footer>
          </blockquote>
        </div>
      </section>

    
      <div className='bg-move contact-btn text-center mt-5'>
            
            <CallToAction text="Contactez Safari Participations dès aujourd'hui pour des solutions adaptées à vos besoins."/>
        </div>
    </div>
  );
};

export default SafariParticipations;
