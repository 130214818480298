import React, {useState, useEffect } from "react";

const GreetingModal = () => {
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        setShowModal(true);
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <div>
            {showModal && (
                <>
                    <div className="modal-backdrop" style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        backdropFilter: 'blur(5px)',
                        WebkitBackdropFilter: 'blur(5px)',
                        zIndex: 1040
                    }}></div>
                    <div className="modal show d-block" style={{ zIndex: 1050}}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content text-center" style={{
                                borderRadius: '15px',
                                boxShadow: '0 0 20px rgba(0,0,0,0.2)',
                                background: 'url("https://img.freepik.com/vecteurs-libre/fond-hiver-non-localise-flocons-neige_1393-271.jpg")'

                            }}>
                                <div className="modal-header border-bottom-0">
                                    <h5 className="modal-title w-100" style={{
                                        color: '#dc3545',
                                        fontSize: '2.5rem',
                                        fontFamily: "'Dancing Script', cursive",
                                        textShadow: '2px 2px 4px rgba(0,0,0,0.1),',
                                    }}>✨ Joyeuses Fêtes 🎄</h5>
                                    <button 
                                        type="button"
                                        className="btn-close"
                                        onClick={handleCloseModal}
                                    ></button>
                                </div>
                                <div className="modal-body py-4">
                                        <img 
                                            src="https://img.freepik.com/premium-photo/2025-happy-new-year-with-christmas-background_1019772-4351.jpg" 
                                            alt="Festive" 
                                            className="mb-4"
                                            style={{
                                                width:'100%',
                                                height:'300px',
                                                borderRadius: '10px',
                                                boxShadow: '0 4px 15px rgba(0,0,0,0.1)'
                                            }}
                                        />
                                        <p style={{ 
                                            fontSize: '1.4rem',
                                            lineHeight: '1.6',
                                            color: '#2c3e50',
                                        }}>
                                            <strong style={{color: '#28a745'}}>Safari Technologies</strong> vous souhaite de
                                            joyeuses fêtes et les meilleurs vœux pour l'année <strong style={{color: '#dc3545'}}>2025</strong>!
                                        </p>
                                </div>
                                <div className="modal-footer border-top-0 justify-content-center">
                                    <button 
                                        className="btn btn-lg px-4 py-2 mt-0" 
                                        onClick={handleCloseModal}
                                        style={{
                                            background: 'linear-gradient(45deg, #dc3545, #e83e8c)',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '25px',
                                            fontSize: '1.2rem',
                                            boxShadow: '0 4px 15px rgba(220,53,69,0.3)'
                                        }}
                                    >
                                       À bientôt ! 🎉
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default GreetingModal;

