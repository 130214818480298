import axios from 'axios';

const API_BASE_URL = 'https://intranet.safari-technologies.com/api';

export const fetchJobs = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/annonces`);
    console.log('Fetched Jobs:', response.data);
    return response.data; 
  } catch (error) {
    console.error('Error fetching jobs:', error.response || error.message);
    return [];
  }
};
