import Header from "../../components/Header";
import Systems from "../../components/Solutions/Systems/Systems";
import Footer from "../../components/Footer";
import MainSection from "../../components/MainSection";
import img from "../../assets/images/s4.jpg"



function SystemsPage(){
    return(
        <div>
            <Header />
            < MainSection title="Solutions Systèmes Fiables" text="Explorez nos solutions systèmes conçues pour assurer la stabilité, l’efficacité et la sécurité de vos environnements informatiques. 
                Que ce soit pour la mise en place, l’intégration ou la maintenance de vos systèmes, nos experts sont là pour garantir un fonctionnement optimal de vos infrastructures."
                    btn="Faites appel à nos consultants" backgroundImage={img}/>
            <Systems />
            <Footer />
        </div>

    );
}


export default SystemsPage;