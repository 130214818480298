import React from "react";
import "../../../assets/css/Network.css";
import illustration from "../../../assets/images/network.jpg";


const Network = () => {
    return(
        <div className="network-container">
            <h1 className="network-title" data-aos="fade-down" id="p1">Practice Réseau</h1>
            
            <div className="container">
                <p className="network-intro" data-aos="zoom-in">
                    La practice réseau rassemble l’ensemble de nos compétences dans le domaine du Lan, du Wan et de la téléphonie.
                    Safari peut ainsi assurer des prestations sur ces domaines sur tout le spectre projet, allant de l’ingénierie à la
                    production.
                </p>
            </div>

            <div className="content">
                <h2 data-aos="fade-up">
                    3 Étapes
                </h2>
                <div className="row" data-aos="fade-down">
                    <div className="network-card col align-self-start">
                        <h3>Support en Conception et en Production</h3>
                        <p><li>Les missions d’ingénierie conception sont délivrées par des ingénieurs, des chefs de projets et des architectes réseau
                            disposant de certifications Editeurs/Constructeurs. </li></p> <p><li>Notre politique de formation permet de maintenir certifiés nos
                            ingénieurs.</li></p><p>
                            <li>Cette approche offre une garantie de qualité au service de nos clients.</li>
                        </p>
                    </div>

                    <div className="network-card col align-self-center card2">
                        <h3>Infogérance</h3>
                        <p><li>Nos prestations d’infogérance se font soit sur site client en mode « Insourcing », soit en mode outsourcing dans notre data center à Saint Denis. </li></p>
                        <p><li>Nous assurons des prestations en mode régie ou forfait, permettant à nos clients de contrôler et optimiser leurs coûts tout en bénéficiant d’une qualité de service élevée. </li></p> 
                        <p><li>Offrir un service de qualité en gestion de réseau garantit la disponibilité d’un système informatique opérationnel.</li></p>
                    </div>

                    <div className="network-card col align-self-center card3">
                        <h3>Déploiement</h3>
                        <p><li>Les missions de déploiement sont assurées soit par des ingénieurs, par des coordinateurs, des chefs de projets et
                            des techniciens. </li></p> 
                            <p><li>SAFARI couvre la palette de déploiement allant de la masterisation jusqu’à la mise en œuvre sur
                            site.
                            </li></p>
                    </div>
                </div>
            </div>

            <div className="network-image mt-2" data-aos="fade-up">
                <img src={illustration} alt="Network Illustration" className="img-fluid rounded" />
            </div>

            

            
        </div>

    );
}



export default Network