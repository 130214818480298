import Header from "../../components/Header";
import ITCloud from "../../components/Solutions/Cloud/Cloud";
import Footer from "../../components/Footer";
import MainSection from "../../components/MainSection";
import img from "../../assets/images/s5.png"

function ITCloudPage(){
    return(
        <div>
            <Header />
            < MainSection title="Conseils IT & Cloud" text="Adoptez une transformation numérique réussie avec nos services de conseils IT et solutions Cloud. 
                Nous vous accompagnons dans le choix, l’intégration et la gestion des technologies adaptées à vos besoins pour un avenir digital optimisé."
                    btn="Faites appel à nos consultants" backgroundImage={img}/>
            <ITCloud />
            <Footer />
        </div>

    );
}


export default ITCloudPage;