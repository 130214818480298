import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MainSection from "../../components/MainSection";
import Network from "../../components/Solutions/NetworkSecurity/Network";
import Security from "../../components/Solutions/NetworkSecurity/Security";
import img from "../../assets/images/s2.jpg"



function NetworkPage(){
    return(
        <div>
            < Header/>
            < MainSection title="Solutions Réseau Innovantes" text="Découvrez nos solutions réseau qui garantissent une connectivité fiable et sécurisée pour votre entreprise. 
                    Que vous ayez besoin d'une infrastructure réseau robuste ou de services de gestion de réseau, nous avons les compétences et l'expertise nécessaires pour répondre à vos besoins."
                    btn="Faites appel à nos consultants" backgroundImage={img}/>
            < Network />
            <Security />
            <Footer /> 
        </div>
    );
}



export default NetworkPage;