import React from 'react';
import {FaCloud, FaShieldAlt,FaIndustry, FaCogs } from 'react-icons/fa';
import '../../assets/css/Industries.css';
import CallToAction from '../CallToActions';
import { Link } from 'react-router-dom';
import indus1 from "../../assets/images/indus1.jpg";
import indus2 from "../../assets/images/indus2.jpg";




const Industries = () => {
  return (
    <div className="industries-container">
      <header className="industries-header">
        <FaIndustry className="industries-icon" />
        <hr class="hr hr-blurry" />
        <h2 className="industries-title">Nos Solutions pour l'Industrie</h2>
        <p className="industries-subtitle">
          Safari L'ESN accompagne les entreprises industrielles dans leur transformation numérique grâce à des solutions fiables et adaptées.
        </p>
        <img
          className="industry-img"
          src={indus1}
          alt="Industrial Solutions"
        />
      </header>
      <main className="industries-content">
        <section className="industries-introduction">
          <h3 className="section-title">Pourquoi les solutions IT sont essentielles pour l'industrie ?</h3>
          <p className="industries-text">
            Dans un monde de plus en plus connecté, les entreprises industrielles doivent relever des défis tels que l'optimisation des processus, la sécurité des données, et la gestion de réseaux complexes. Les solutions IT jouent un rôle clé pour :
          </p>
          <ul className="industries-list">
            <li>Améliorer l'efficacité opérationnelle et réduire les coûts.</li>
            <li>Garantir la sécurité des systèmes critiques et des données sensibles.</li>
            <li>Favoriser l'innovation en adoptant des technologies modernes comme le cloud et l'automatisation.</li>
          </ul>
        </section>
        <section className="">
        <h3 className="section-title">Nos Solutions Technologiques Pour l'industrie</h3>
        <p className="flow-text">
          Nous vous accompagnons dans votre transformation numérique avec des solutions adaptées à chaque étape de votre parcours technologique.
        </p>
        <div className="flow-container">
          <Link to="/solutions/ITCloud" className="flow-step">
            <div className="flow-icon">
              <FaCloud />
            </div>
            <h3 className="flow-title">Cloud Computing</h3>
            <p className="flow-description">
              Déployez des infrastructures cloud fiables et sécurisées pour une meilleure agilité et scalabilité.
            </p>
          </Link>
          <div className="flow-arrow">→</div>
          <Link to="/solutions/reseau" className="flow-step">
            <div className="flow-icon">
              <FaCogs />
            </div>
            <h3 className="flow-title">Réseaux et Sécurité</h3>
            <p className="flow-description">
              Installez des réseaux robustes et sécurisés pour assurer la continuité de vos opérations et protéger vos données critiques.
            </p>
          </Link>
          <div className="flow-arrow">→</div>
          <Link to="/solutions/ApplicationsERP" className="flow-step">
            <div className="flow-icon">
              <FaShieldAlt />
            </div>
            <h3 className="flow-title">ERP Systems</h3>
            <p className="flow-description">
              Centralisez vos opérations et améliorez la gestion de vos ressources avec des systèmes ERP intégrés et personnalisés.
            </p>
          </Link>
        </div>
        </section>

        <img
          className="industry-img"
          src={indus2}
          alt="Industrial Solutions"
        />


        <section className="industries-section">
            <h3 className="section-title mt-5">Nos Engagements pour votre Industrie</h3>
            <p className="industries-text">
                Chez Safari, nous mettons tout en œuvre pour répondre aux besoins spécifiques des entreprises industrielles. Notre approche repose sur trois piliers :
            </p>
            <div className="industries-benefits">
                <div className="benefit-card">
                <h4 className="benefit-title">Fiabilité et Performance</h4>
                <p className="benefit-text">
                    Nous assurons la continuité de vos opérations grâce à des infrastructures robustes et des technologies éprouvées.
                </p>
                </div>
                <div className="benefit-card">
                <h4 className="benefit-title">Solutions Personnalisées</h4>
                <p className="benefit-text">
                    Chaque entreprise a ses propres défis. Nos experts conçoivent des solutions sur mesure adaptées à vos objectifs.
                </p>
                </div>
                <div className="benefit-card">
                <h4 className="benefit-title">Accompagnement et Expertise</h4>
                <p className="benefit-text">
                    De l'analyse initiale à la mise en œuvre, notre équipe vous accompagne à chaque étape pour garantir le succès de votre transformation numérique.
                </p>
                </div>
            </div>
        </section>
      </main>
      < CallToAction text="Prêt à transformer votre industrie?"/>
    </div>
  );
};

export default Industries;
