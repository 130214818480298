import React from 'react';
import '../../../assets/css/Security.css';
//import securityImage from '../../assets/images/security.jpg'; 
import { FaShieldAlt, FaExclamationCircle, FaLock, FaLightbulb } from 'react-icons/fa'; 
import CallToActions from "../../CallToActions";


const Security= () => {
  return (
    <div className="security-practice-container">
      <h1 className="security-title" id='p2'>Practice Sécurité</h1>
      <div className="security-content">
        <div className="security-text">
          <div className="security-card">
            <FaShieldAlt className="security-icon" />
            <p>
              Les malwares et autres virus étant de plus en plus dangereux et encore plus difficiles à contrer, 
              les comportements des utilisateurs des technologies informatiques sont de plus en plus intenses.
            </p>
          </div>
          <div className="security-card">
            <FaExclamationCircle className="security-icon" />
            <p>
              Les risques d'attaques économiques sont plus présents que jamais. Fort de ce constat, nous devons affronter 
              un arsenal de risques d’autant plus important.
            </p>
          </div>
          <div className="security-card">
            <FaLock className="security-icon" />
            <p>
              Les systèmes et logiciels composant un Système d’Informations comportent généralement des failles de sécurité, 
              qui sont corrigées par les éditeurs au fur et à mesure de leur publication, ce qui rend quasi-impossible le maintien d’une 
              infrastructure totalement sécurisée.
            </p>
          </div>
        </div>
        {/*
        <div className="security-image">
          <img src={securityImage} alt="Security Illustration" />
        </div>*/}
      </div>
      <div className="security-footer">
        
        <p>
        <FaLightbulb className='light-icon' />
          Il est donc nécessaire de mettre en place une politique de sécurité des systèmes d’information (PSSI) 
          et de s’assurer de son application au sein de votre entreprise.
        </p>
      </div>


      
      <h3 className='mt-5 questions-title'>
        Les questions que nous nous posons quotidiennement sur les sujets sécurité sont:
      </h3>

      <table className='table table-bordered table-hover mt-2 p-5'>
      
        <caption>Solutions + Services Managés</caption>
          <thead>
            <tr>
              <th scope='col' className='bg-beige'>Suis-je Vulnérable?</th>
              <th scope='col' className='bg-beige'>Suis-je compromis?</th>
              <th scope='col' className='bg-beige'>Suis-je optimisé?</th>
            </tr>
          </thead>

          <tbody>
            <tr className='table-primary'>
              <td>Threat Exposure Management</td>
              <td>Incident Detection & Response</td>
              <td>Log Management & IT Analytics</td>
            </tr>
           
            <tr className='table-primary'>
              <td>VULNERABILITY MANAGEMENT</td>
              <td>USER BEHAVIOR ANALYTICS</td>
              <td>ENDPOINT VISIBILITY & ASSET MANAGEMENT</td>
            </tr>

            <tr className='table-primary'>
              <td>APPLICATION SECURITY TESTING</td>
              <td>INCIDENT DETECTION & RESPONSE</td>
              <td>INFRASTRUCTURE MONITORING & TROUBLESHOOTING</td>
            </tr>

            
            <tr className='table-primary'>
              <td>ATTACK SIMULATION</td>
              <td>ENDPOINT VISIBLITY & INTERROGATION</td>
              <td>LOG MANAGEMENT & COMPLIANCE</td>
            </tr>
          </tbody>
        </table>

        <div className='color-purple'>
          <h4 className='mt-5'>
            Le groupe SAFARI a fait le choix de regarder l’ensemble des risques de sécurité des systèmes informatiques de
            manière globale et de proposer des solutions et conseils adaptés à chaque famille de risque.
          </h4>
          
          <div className='bg-beige p-2 mt-5'>
            <h4>Dans cette optique, nous avons développé une practice Sécurité des Systèmes d’Information.</h4>

            <h4 className='mt-3'>Cette practice assure de missions de :</h4>

            <ul className='bullets'>
              <li>
                <span className='list-security'>Concpetion:</span> Audit et mise en place des PSSI
              </li>
              <li>
                <span className='list-security'>D'ingénierie:</span> Mise en place des outils de sécurité informatique
              </li>
              <li>
                <span className='list-security'>MCO:</span> Supervision des solutions de sécurités 
              </li>
            </ul>
          </div>
          
        </div>

        <div className='cta-back'>
        <CallToActions text="Vous avez besoin d'une infrastrucutre réseau robuste et sécurisée?"/>
        </div>



        
    </div>
  );
};

export default Security;
