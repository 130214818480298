import React, {useState, useEffect} from "react";
import Header from "../Header";
import Footer from "../Footer";
import "../../assets/css/Jobs.css";
import { FaHome } from "react-icons/fa";
import { FaRegFileAlt } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import {fetchJobs} from "./fetchJobs";



const JobOffers = () => {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const getJobs = async () => {
      try {
        const jobData = await fetchJobs(); 
        const activeJobs = jobData.filter((job) => job.statut === "active"); 
        setJobs(activeJobs);
        console.log(activeJobs); 
      } catch (err) {
        console.error(err);
        setError('Failed to fetch job offers.');
      }
    };
  
    getJobs();
  }, []);

  const viewJob = (jobId) => {
    navigate(`${jobId}`);
  };

  
  if (error) return <p>{error}</p>;

  return (
    <div>
      <Header />
      <h1 className="text-center mb-5 mt-5">Nos Offres d'emploi</h1>
      <div className="container p-5 bg-light mb-5 rounded-5">
        <h3>
          <strong>{jobs.length} Offres</strong>
        </h3>
        <div className="row">
          {jobs.map((job) => (
            <div className="col-md-6 mb-4" key={job.id}>
              <div className="card h-100 shadow-sm p-3">
                <div className="card-body">
                  <h5 className="card-title">{job.intitule}</h5>
                  <p className="card-subtitle text-muted">
                    {job.localisation}
                  </p>
                  <p className="text-primary job-salary">{job.salaire_annuel}€</p>
                  <p>
                    <FaHome /> <strong>Télétravail:</strong> {job.teletravail === 1 ? 'Oui' : 'Non'}
                  </p>
                  <p>
                    <FaRegFileAlt /> <strong>Contrat:</strong> {job.type_id}
                  </p>
                  <p className="text-muted">
                    <small>{job.posted}</small>
                  </p>
                  <button className="voir-btn" onClick={() => viewJob(job.id)}>
                    Voir l'offre
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JobOffers;
