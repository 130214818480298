import React, { useState } from "react";
import {Modal, Button, Form} from 'react-bootstrap';
import axios from 'axios';


const JobModal = ({show, handleClose, jobTitle,jobId}) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        cv: null
    });

    const [showSuccess, setShowSuccess] = useState(false);
    const [showAlreadyApplied, setShowAlreadyApplied] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formDataObj = new FormData();
        formDataObj.append('nom', formData.lastName); 
        formDataObj.append('prenom', formData.firstName); 
        formDataObj.append('email', formData.email);
        formDataObj.append('telephone', formData.phone);
        formDataObj.append('cv', formData.cv);
        formDataObj.append('message', "I am very interested in this role!"); 
    
        try {
            const response = await axios.post(`https://intranet.safari-technologies/api/annonces/${jobId}/apply`, formDataObj, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
    
            // Success message
            console.log(response.data.message);
            setShowSuccess(true);
        } catch (err) {
            if (err.response && err.response.status === 409) {
                setShowAlreadyApplied(true);
            } else {
                console.error(err.response?.data || err.message);
                alert('Failed to send application.');
            }
        }
    
        // Reset form and close modal
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            cv: null,
        });
    
        handleClose();
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            cv: e.target.files[0]
        });
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        const numbersOnly = value.replace(/[^\d]/g, '').slice(0,10);

        setFormData({
            ...formData, 
            phone: numbersOnly
        });
    };

    return (
        <>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Postuler - {jobTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            value={formData.lastName}
                            placeholder="Votre Nom..."
                            onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                            />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            value={formData.firstName}
                            placeholder="Votre Prénom..."
                            onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                            />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            required
                            placeholder="Votre email..."
                            value={formData.email}
                            onChange={(e) => setFormData({...formData, email: e.target.value})}
                            />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Téléphone</Form.Label>
                        <div className="input-group">
                            <span className="input-group-text">+33</span>
                            <Form.Control
                                type="tel"
                                required
                                value={formData.phone}
                                onChange={handlePhoneChange}
                                placeholder="Votre numéro de téléphone..."
                                pattern="[0-9]{10}"
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CV</Form.Label>
                        <Form.Control
                            type="file"
                            required
                            placeholder="Votre CV..."
                            accept=".pdf, .doc, .docx"
                            onChange={handleFileChange}
                            />
                        <Form.Text className="text-muted">
                            Formats acceptés: PDF, DOC, DOCX
                        </Form.Text>
                    </Form.Group>

                    <div className="d-flex justify-content-end gap-2">
                        <Button variant="secondary" onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="primary" type="submit">
                            Envoyer
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>

        {/* Success Modal */}
        <Modal
                show={showSuccess}
                onHide={() => setShowSuccess(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Candidature envoyée !</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center py-4">
                    <div className="mb-3">
                        <i className="fas fa-check-circle text-success" style={{ fontSize: '48px' }}></i>
                    </div>
                    <p>Votre candidature a été envoyée avec succès.</p>
                    <p>Nous étudierons votre profil et reviendrons vers vous rapidement.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button 
                        onClick={() => setShowSuccess(false)}
                    >
                        Fermer
                    </Button>
                </Modal.Footer>
        </Modal>

         {/* Already Applied Modal */}
         <Modal show={showAlreadyApplied} onHide={() => setShowAlreadyApplied(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Vous avez déjà postulé</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center py-4">
                    <div className="mb-3">
                        <i className="fas fa-exclamation-circle text-warning" style={{ fontSize: '48px' }}></i>
                    </div>
                    <p>Vous avez déjà postulé à cette offre d'emploi.</p>
                    <p>Vous allez avoir une réponse prochainement.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button onClick={() => setShowAlreadyApplied(false)}>Fermer</Button>
                </Modal.Footer>
        </Modal>
    </>
    );
};


export default JobModal;
